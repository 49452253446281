<section id="module-j" class="container">
  <div class="w-full md:w-22/24 md:ml-1/24 flex flex-col md:flex-row gap-6 md:gap-3">
    <div class="w-full md:w-12/24 px-8 sm:px-1/24 rounded-2 sm:rounded-6 bg-blue-300 py-7 text-white flex flex-col gap-4 md:gap-5">
      <div class="scroll-fade bg-white/20 px-[10px] py-2 sm:py-3 rounded-3 flex flex-col items-center">
        <span class="text-12 md:text-18 font-700" i18n="@@montenegrin_cards">
          Crnogorske kartice
        </span>
        <span class="text-26 sm:text-40 leading-122">
          <span i18n="@@from">od</span>
          <span class="text-38 sm:text-64 font-800"> 1,8</span>
          <span><strong>%</strong> + 0,20€</span>
        </span>
        <span class="text-12 sm:text-18 leading-122" i18n="@@per_transaction">
          po transakciji
        </span>
      </div>
      <div class="scroll-fade bg-white/20 px-[10px] py-2 sm:py-3 rounded-3 flex flex-col items-center">
        <span class="text-12 md:text-18 font-700" i18n="@@ino_cards">
          Ino kartice
        </span>
        <span class="text-26 sm:text-40 leading-122">
          <span i18n="@@from">od</span>
          <span class="text-38 sm:text-64 font-800"> 3</span>
          <span><strong>%</strong> + 0,20€</span>
        </span>
        <span class="text-12 sm:text-18 leading-122" i18n="@@per_transaction">
          po transakciji
        </span>
      </div>
      <div class="scroll-fade mt-6 sm:mt-9 flex items-center justify-center gap-2 sm:gap-3">
        <img src="assets/img/logos/mastercard.svg" alt="mastercard" class="w-[39px] h-[21px] sm:w-[47px] sm:h-[37px]">
        <img src="assets/img/logos/visa.svg" alt="VISA" class="w-[43px] h-[14px] sm:w-[73px] sm:h-[24px]">
      </div>
    </div>
    <div class="w-full md:w-12/24 px-2/24 py-12 sm:py-17 rounded-3 sm:rounded-6 border border-gray-100">
      <div class="w-full h-12 sm:h-16 bg-gray-50 rounded-full flex text-12 sm:text-14 leading-133 font-700 relative z-20">
        <div class="absolute inset-0 z-10">
          <div class="w-12/24 h-full rounded-full bg-gray-700 translate-x-full transition-transform duration-400 ease-in-out"
               [ngClass]="{'translate-x-0': yearlyPricingVisible,
                           'translate-x-full': !yearlyPricingVisible}">
          </div>
        </div>
        <button (click)="showMonthlyPricing()"
                (mouseup)="showMonthlyPricing()"
                class="relative z-20 w-12/24 h-full flex items-center justify-center transition-colors duration-400 ease-out"
                [ngClass]="{'text-white': yearlyPricingVisible,
                            'text-gray-700': !yearlyPricingVisible}">
          <span i18n="@@yearly_payment" class="scroll-fade">Godišnje plaćanje</span>
        </button>
        <button (click)="showYearlyPricing()"
                (mouseup)="showYearlyPricing()"
                class="relative z-20 w-12/24 h-full flex items-center justify-center transition-colors duration-400 ease-out"
                [ngClass]="{'text-gray-700': yearlyPricingVisible,
                            'text-white': !yearlyPricingVisible}">
          <span i18n="@@monthly_payment" class="scroll-fade">Mjesečno plaćanje</span>
        </button>
        <img class="scroll-fade absolute -top-5 sm:-top-10 -left-5 sm:-left-12 sm:-rotate-[33deg] w-5 sm:w-auto" src="assets/img/modules/module_j_arrow.svg" alt="graphics">
        <span class="scroll-fade absolute -top-8 sm:-top-14 left-2 sm:left-0 text-[#EDC43B] text-16 sm:text-22 italic font-900">
          <span>25% </span>
          <span i18n="@@worth_of_savings">uštede</span>
        </span>
      </div>
      <div class="relative scroll-fade">
        <div class="mt-8 w-full h-22 sm:h-30 bg-gray-50 py-2 flex flex-col items-center text-gray-700 rounded-3 sm:rounded-4 relative overflow-hidden">
          <div class="absolute top-0 bottom-0 left-0 w-[200%] flex transition-transform duration-400 ease-springy"
               [ngClass]="{'translate-x-0': !yearlyPricingVisible, '-translate-x-[50%]': yearlyPricingVisible}">

            <!--Monthly payment-->
            <div class="w-full h-full flex flex-col items-center justify-center">
              <div class="flex">
                <span class="text-48 font-700 leading-100">27</span>
                <span class="text-20">,99€</span>
              </div>
              <span>
                <span i18n="@@monthly" class="text-14 sm:text-20 font-500">mjesečno</span>
                <span class="text-10 sm:text-14 font-400"> (</span>
                <span i18n="@@no_pdv" class="text-10 sm:text-14 font-400">bez PDV-a</span>
                <span class="text-10 sm:text-14 font-400">)</span>
              </span>
            </div>

            <!--Yearly payment-->
            <div class="w-full h-full flex flex-col items-center justify-center">
              <div class="flex">
                <span class="text-48 font-700 leading-100">20</span>
                <span class="text-20">,83€</span>
              </div>
              <span>
                <span i18n="@@monthly" class="text-14 sm:text-20 font-500">mjesečno</span>
                <span class="text-10 sm:text-14 font-400"> (</span>
                <span i18n="@@no_pdv" class="text-10 sm:text-14 font-400">bez PDV-a</span>
                <span class="text-10 sm:text-14 font-400">)</span>
              </span>
            </div>

          </div>
        </div>
      </div>
      <div class="scroll-fade w-full text-center flex-1 pt-5 sm:pt-[14px]">
        <span class="text-12 sm:text-14 leading-122 text-gray-700 transition-opacity duration-300"
              [ngClass]="{'opacity-100 delay-400': yearlyPricingVisible, 'opacity-0 delay-0': !yearlyPricingVisible}">
          <span class="font-800">250,00€ </span>
          <span class="font-400" i18n="@@for_a_year_in_advance">za godinu dana unaprijed</span>
        </span>
      </div>
      <div class="scroll-fade text-center">
        <button (click)="openTypeform()"
                class="button-medium--dark inline-block mt-5 sm:mt-7 sm:!px-18 w-16/24 sm:w-auto"
                i18n="@@send_query">
          Pošalji upit
        </button>
      </div>
    </div>
  </div>
  <div class="w-full md:w-20/24 md:ml-2/24 mt-6 sm:text-center text-14 sm:text-18 leading-150 text-gray-700 pr-5 sm:pr-0">
    <span i18n="@@pricing_description1" class="scroll-fade">
      Postavka sistema plaća se jednokratno i iznosi 195,00€ bez PDV-a.
      <br/> Za više informacija o iznosima bankarskih provizija za vašu oblast poslovanja, pošaljite upit.
    </span>
  </div>
</section>

