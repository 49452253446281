import { Component } from '@angular/core';
import { TypeformService} from '../../../../services/typeform.service';

@Component({
  selector: 'app-presto-link-module-j',
  templateUrl: './presto-link-module-j.component.html'
})
export class PrestoLinkModuleJComponent {

  yearlyPricingVisible: boolean = true;

  constructor(private typeformService: TypeformService) {
  }

  showMonthlyPricing(): void {
    this.yearlyPricingVisible = true;
  }

  showYearlyPricing(): void {
    this.yearlyPricingVisible = false;
  }

  openTypeform(): void {
    this.typeformService.openTypeform();
  }
}
