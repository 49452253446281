import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ScrollFadeService {

  constructor(@Inject(PLATFORM_ID) private platformId,) {
  }

  private intersectionObserver: IntersectionObserver;
  private created: boolean = false;

  public init(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if (this.created) {
      return;
    }

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.20
    };

    this.intersectionObserver = new IntersectionObserver(this.handleIntersect, options);

    this.created = true;
  }

  private handleIntersect(entries): void {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const element = entry.target;

        element.style.opacity = '1';
        element.style.transform = 'translateY(0)';
      }
    });
  }

  public applyScrollFade(elements: NodeListOf<Element>): void {
    if (!this.created) {
      return;
    }

    elements.forEach((element: HTMLElement) => {
      element.style.opacity = '0';
      element.style.transform = 'translateY(32px)';

      this.intersectionObserver.observe(element);
    });
  }

  public disconnect(): void {
    if (!this.created) {
      return;
    }

    this.intersectionObserver.disconnect();
  }

  public destroy(): void {
    if (!this.created) {
      return;
    }

    this.disconnect();
    delete this.intersectionObserver;
  }
}
