<div class="z-100 w-full max-w-max fixed top-0">
  <div class="relative container pt-8">
    <div
      class="z-100 relative w-full md:w-18/24 md:ml-3/24 min-h-[56px] sm:min-h-[66px] bg-white rounded-full pl-5 sm:pl-9 pr-2 pt-2 pb-2 shadow flex items-center justify-between transition-shadow duration-150 ease-linear"
      [ngClass]="{'shadow': !menuVisible}">
      <button (click)="handleLogoClick()">
        <img src="assets/img/logos/prestolink-logo-dark-xs.png" alt="PrestoLink" i18n-title="@@back_to_homepage" title="Nazad na početnu" aria-label="Nazad na početnu" width="95" height="19">
      </button>

      <div class="hidden sm:flex items-center gap-6 md:gap-10 text-14 leading-150 font-600 text-gray-900">
        <button *ngFor="let item of headerItems"
                (click)="handleHeaderItemClick(item.target)">
          <span i18n="{{ item.i18n }}" class="underline decoration-transparent hover:decoration-black transition-colors duration-200">
            {{ item.label }}
          </span>
        </button>
      </div>

      <button
              class="hidden sm:block button-medium--light"
              (click)="openTypeform()">
        <span i18n="@@header_button_label">Pošalji upit</span>
      </button>

      <button class="block sm:hidden p-3 rounded-full bg-gray-900" (click)="toggleMenu()" aria-label="Meni">
        <div class="w-4 h-4 relative">
          <div class="absolute left-0 top-px right-0 h-05 bg-white rounded-2 transition-transform duration-150 ease-in-out"
               [ngClass]="{'translate-y-[6px] rotate-45' : menuVisible, 'translate-y-0 rotate-0': !menuVisible}"></div>
          <div class="absolute top-[7px] left-0 right-0 h-05 bg-white rounded-2 transition-opacity ease-linear duration-200"
               [ngClass]="{'opacity-0' : menuVisible, 'opacity-100': !menuVisible}"></div>
          <div class="absolute bottom-px left-0 right-0 h-05 bg-white rounded-2 transition-transform duration-150 ease-in-out"
               [ngClass]="{'-translate-y-[6px] -rotate-45' : menuVisible, 'translate-y-0 rotate-0': !menuVisible}"></div>
        </div>
      </button>
    </div>

    <div class="block sm:hidden relative z-50 -mt-12 w-20/24 ml-2/24">
      <button *ngFor="let item of headerItems, let i = index"
              class="absolute -top-px w-full text-center p-3 bg-white rounded-full transition-transform duration-500 ease-in-out"
              (click)="handleHeaderItemClick(item.target)"
              [ngStyle]="menuVisible && {'transform': 'translateY(' + (i + 1) * 54 + 'px)'}">
        <span class="text-16 leading-150 text-black font-700" i18n="{{ item.i18n }}">
          {{ item.label }}
        </span>
      </button>
      <button class="absolute -top-px w-full button-medium--dark !transition-transform !duration-500 !ease-in-out"
              (click)="openTypeform()"
              i18n="@@header_button_label"
              [ngStyle]="menuVisible && {'transform': 'translateY(' + (headerItems.length + 1) * 54 + 'px)'}">
        Pošalji upit
      </button>
    </div>
  </div>
</div>

<div class="block sm:hidden z-50 fixed w-full h-screen bg-[#0f0f0f] opacity-0 transition-opacity duration-150 ease-linear"
     [ngClass]="{'!opacity-50': menuVisible, 'pointer-events-none': !menuVisible}">
</div>
