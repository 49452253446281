import { RouteName } from '../../routes';

export class SeoItem {
  title: string = '';
  description: string = '';
  keywords: string = '';
  image: string = '';
  imageAlt: string = '';
  url: string = '';
  route_name?: RouteName;
  ogType: string = 'website';
  canonical_url: string = '';
  seo_title: string = '';
  seo_description: string = '';
  seo_keywords: string = '';
  seo_fb_title: string = '';
  seo_fb_description: string = '';
  seo_fb_type: string = '';
  seo_fb_image_url: string = '';
  seo_tw_title: string = '';
  seo_tw_description: string = '';
  seo_tw_type: string = '';
  seo_tw_image_url: string = '';
  seo_tw_site: string = '';
  robots: string = '';
  index: boolean = true;
  follow: boolean = true;

  public toTransliterate = [
    'title',
    'description',
    'keywords',
    'seo_title',
    'seo_description',
    'seo_keywords',
    'seo_fb_title',
    'seo_fb_description',
    'seo_tw_title',
    'seo_tw_description'
  ];

  public constructor(data?: Partial<SeoItem>) {
    Object.assign(this, data);
  }

  getTitle(): string {
    return this.seo_title ? this.seo_title : this.title;
  }

  getDescription(): string {
    return this.seo_description ? this.seo_description : this.description;
  }
}
