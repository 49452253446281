<section id="module-h" class="z-20 w-full sm:container sm:px-0 h-[612px] sm:h-[500px] md:h-[700px] relative">
  <div class="w-full h-full relative">
    <div class="absolute inset-0 gradient-{{ items[activeIndex]?.gradient }} sm:rounded-6 transition-[background-color] duration-500 ease-linear">
      <div class="container sm:!mx-0">
        <div class="scroll-fade w-20/24 sm:w-12/24 md:w-8/24 sm:ml-3/24 mt-8 sm:mt-[54px] relative">
          <div *ngFor="let item of items, let i = index" class="absolute inset-0">
            <span class="font-poppins font-700 sm:font-800 text-white text-24 md:text-34 leading-140 sm:leading-122 transition-opacity duration-150 ease-linear"
                  [ngClass]="{'opacity-0': i !== activeIndex, 'opacity-100': i === activeIndex}">
              {{ item.title }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="absolute inset-0">
      <div class="z-10 w-full h-full swiper swiper-module-h scroll-fade">
        <div class="swiper-wrapper">
          <div *ngFor="let item of items" class="swiper-slide sm:px-1/24 pb-15 flex flex-col justify-end">
            <picture class="w-[340px] sm:w-full mx-auto swiper-lazy">
              <source data-srcset="{{ item.url_mobile }}.webp" type="image/webp" media="(max-width: 768px)">
              <source data-srcset="{{ item.url }}.webp" type="image/webp" media="(min-width: 768px)">
              <source data-srcset="{{ item.url_mobile }}.png" type="image/png" media="(max-width: 768px)">
              <source data-srcset="{{ item.url }}.png" type="image/png" media="(min-width: 768px)">
              <img class="w-full swiper-lazy"
                   data-srcset="{{ item.url_mobile }}.png"
                   alt="slider graphics"
                   width="340" height="405">
            </picture>
          </div>
        </div>
      </div>
      <div class="scroll-fade hidden sm:flex gap-4 absolute z-20 top-[54px] right-[54px] md:right-[156px] text-white">
        <button class="nav-left w-11 h-11 border-2 border-white rounded-full bg-white bg-opacity-0 hover:bg-opacity-20"
                aria-label="Slider navigation - left">
          <fa-icon [icon]="faChevronLeft" class="text-22"></fa-icon>
        </button>
        <button class="nav-right w-11 h-11 border-2 border-white rounded-full bg-white bg-opacity-0 hover:bg-opacity-20"
                aria-label="Slider navigation - right">
          <fa-icon [icon]="faChevronRight" class="text-22"></fa-icon>
        </button>
      </div>
      <div class="scroll-fade absolute bottom-0 left-0 right-0 flex items-center">
        <div class="swiper-pagination h-10"></div>
      </div>
    </div>
  </div>
</section>

