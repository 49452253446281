import {Component, OnDestroy, OnInit} from '@angular/core';
import {ScrollFadeService} from '../../services/scroll-fade.service';

@Component({
  selector: 'app-presto-link',
  templateUrl: './presto-link.component.html'
})
export class PrestoLinkComponent implements OnInit, OnDestroy {

  constructor(private scrollFadeService: ScrollFadeService) {
  }

  ngOnInit(): void {
    this.scrollFadeService.init();
  }

  ngOnDestroy(): void {
    this.scrollFadeService.destroy();
  }
}
