<section id="module-e" class="w-full sm:container sm:px-0">
  <div class="w-full sm:rounded-6 gradient-blue-200 pt-12 md:pt-22 pb-15 md:pb-29 px-1/24">
    <div class="container md:px-auto bg-white rounded-5 pt-10 pb-10 md:pb-15 flex flex-col-reverse md:flex-row items-center gap-y-18">
      <div class="w-20/24 sm:w-14/24 md:w-12/24 md:pl-26">
        <div class="w-full md:w-14/24 text-center md:text-start px-8 md:px-0">
          <h2 class="font-poppins font-800 text-gray-900 text-26 md:text-34 leading-122 scroll-fade" i18n="@@moduleE_title">
            Prijavite se za servis
          </h2>
          <button (click)="openTypeform()"
                  class="button-medium--dark inline-block mt-7 w-full md:w-auto md:!px-18 scroll-fade"
                  i18n="@@send_query">
            Pošalji upit
          </button>
        </div>
      </div>
      <div class="w-20/24 sm:w-9/24 md:w-10/24 md:pr-10">
        <div class="text-18 md:text-20 leading-133 md:leading-130 font-700 text-gray-900 scroll-fade">
          <span i18n="@@moduleE_description">Naplatite svoje proizvode i usluge unaprijed. Ponudite klijentima plaćanje putem linka!</span>
        </div>
        <div class="mt-8">
          <div class="pt-4 pb-3 border-b border-gray-100 flex items-center gap-3">
            <div class="w-6 h-6 shrink-0 rounded-full bg-blue-100 flex items-center justify-center scroll-fade">
              <fa-icon [icon]="faCheck" class="text-12 text-blue-500"></fa-icon>
            </div>
            <span class="text-14 md:text-16 leading-140 md:leading-150 text-gray-900 scroll-fade" i18n="@@moduleE_item1">
              Brzo i jednostavno pokretanje online poslovanja
            </span>
          </div>
          <div class="pt-4 pb-3 border-b border-gray-100 flex items-center gap-3">
            <div class="w-6 h-6 shrink-0 rounded-full bg-blue-100 flex items-center justify-center scroll-fade">
              <fa-icon [icon]="faCheck" class="text-12 text-blue-500"></fa-icon>
            </div>
            <span class="text-14 md:text-16 leading-140 md:leading-150 text-gray-900 scroll-fade">
              <span i18n="@@moduleE_item2_1">Plaćanje</span>
              <span> Visa™ </span>
              <span i18n="@@and">i</span>
              <span> Mastercard™ </span>
              <span i18n="moduleE_item2_2">platnim karticama.</span>
            </span>
          </div>
          <div class="pt-4 pb-3 border-b border-gray-100 flex items-center gap-3">
            <div class="w-6 h-6 shrink-0 rounded-full bg-blue-100 flex items-center justify-center scroll-fade">
              <fa-icon [icon]="faCheck" class="text-12 text-blue-500"></fa-icon>
            </div>
            <span class="text-14 md:text-16 leading-140 md:leading-150 text-gray-900 scroll-fade" i18n="@@moduleE_item3">
              Usklađenost sa bezbjednosnim PCI DSS standardima.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
