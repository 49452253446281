<section id="module-d" class="container -mt-17 -mb-17 sm:mt-0 sm:mb-0">
  <div class="w-full md:w-22/24 md:ml-1/24 flex flex-col md:flex-row gap-3">
    <div class="w-full md:w-12/24 h-[412px] sm:h-auto rounded-6 overflow-hidden sm:hidden md:block relative">
      <picture class="w-full h-full object-cover object-center">
        <source type="image/webp" srcset="{{ mediaUrl }}/img/modules/module_d.webp">
        <img src="{{ mediaUrl }}/img/modules/module_d.jpg"
             class="w-full h-full object-cover object-center"
             alt="graphics"
             loading="lazy"
             width="571"
             height="678">
      </picture>
    </div>
    <div class="w-full md:w-12/24 rounded-6 bg-gray-50">
      <div class="px-2/24 pt-9 sm:pt-16 pb-16 sm:pb-18 flex flex-col">
        <h2 class="font-poppins font-800 text-30 sm:text-34 leading-122 sm:leading-125 text-gray-900 pl-6 sm:pl-0 pr-5 sm:pr-24 scroll-fade" i18n="@@moduleD_title">
          Naplatite proizvode i usluge u samo dva koraka!
        </h2>
        <div class="pt-8 pb-9 flex flex-col gap-4">
          <div class="bg-bcg rounded-2 pl-5 sm:pl-14 sm:pr-28 py-7 scroll-fade">
            <div class="flex items-center gap-2 sm:gap-3">
              <div class="w-6 sm:w-7 h-6 sm:h-7 bg-gray-900 rounded-full flex items-center justify-center">
                <span class="text-white text-14 sm:text-16">1</span>
              </div>
              <span class="text-16 sm:text-20 font-700" i18n="@@moduleD_box1_title">
                Kreirajte ponudu
              </span>
            </div>
            <span class="inline-block mt-3 sm:mt-4 text-16 leading-150 text-gray-300" i18n="@@moduleD_box1_description">
              Opišite proizvod ili uslugu, definišite cijenu i trajanje ponude.
            </span>
          </div>
          <div class="bg-bcg rounded-2 pl-5 sm:pl-14 sm:pr-28 py-7 scroll-fade">
            <div class="flex items-center gap-2 sm:gap-3">
              <div class="w-6 sm:w-7 h-6 sm:h-7 bg-gray-900 rounded-full flex items-center justify-center">
                <span class="text-white text-14 sm:text-16">2</span>
              </div>
              <span class="text-16 sm:text-20 font-700" i18n="@@moduleD_box2_title">
                Pošaljite link za plaćanje
              </span>
            </div>
            <span class="inline-block mt-3 sm:mt-4 text-16 leading-150 text-gray-300" i18n="@@moduleD_box2_description">
              Jedinstveni link za plaćanje možete poslati putem mejla ili poruke.
            </span>
          </div>
        </div>
        <span class="text-16 leading-150 text-gray-300 pl-5 sm:pl-0 pr-5 sm:pr-14 scroll-fade" i18n="@@moduleD_description">
          Klikom na link, klijenti mogu pogledati detalje ponude i platiti bankovnom karticom, a vi ćete u našem sistemu imati uvid u status naplate.
        </span>
      </div>
    </div>
  </div>
</section>
