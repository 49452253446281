<div class="container h-[100vh] pt-46 sm:pt-48 flex flex-col items-center">
  <img src="assets/img/not-found.png" class="w-[222px] h-[179px] sm:w-[362px] sm:h-[292px]" alt="graphics" title="Graphics">
  <span class="mt-6 sm:mt-11 text-center text-14 sm:text-18 leading-150 text-gray-700 font-400 w-full md:w-14/24">
    <span class="font-700" i18n="@@404_oops">Ups!</span>
    <span i18n="@@404_text_line1">
      Stranica koju tražite ne može se pronaći. Moguće je da je premještena ili izbrisana.
    </span>
    <br/>
    <span i18n="@@404_text_line2">
      Molimo provjerite URL ili koristite navigaciju kako biste pronašli ono što tražite.
    </span>
    <a class="inline-block button-medium--dark mt-6 sm:mt-7 !px-14" [routerLink]="['/']" title="Početna" i18n-title="@@homepage">
      <span i18n="@@404_homepage">Početna</span>
    </a>
  </span>
</div>
