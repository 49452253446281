import {AfterViewInit, Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {ScrollFadeService} from '../../../../services/scroll-fade.service';
import {isPlatformBrowser} from '@angular/common';
import {SeoService} from '../../../../services/seo/seo.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-presto-link-layout',
  templateUrl: './presto-link-layout.component.html'
})
export class PrestoLinkLayoutComponent implements AfterViewInit, OnDestroy {

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private seoService: SeoService,
    private activatedRoute: ActivatedRoute,
    private scrollFadeService: ScrollFadeService,
  ) {
  }

  ngAfterViewInit(): void {
    this.seoService.refreshForRoute(this.activatedRoute.snapshot);

    if (isPlatformBrowser(this.platformId)) {
      const fadingElements = document.querySelectorAll('.scroll-fade');
      this.scrollFadeService.applyScrollFade(fadingElements);
    }
  }

  ngOnDestroy(): void {
    this.scrollFadeService.disconnect();
  }

}
