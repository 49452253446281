import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {Router} from '@angular/router';
import {TypeformService} from '../../../../services/typeform.service';

@Component({
  selector: 'app-presto-link-header',
  templateUrl: './presto-link-header.component.html'
})
export class PrestoLinkHeaderComponent implements OnInit {

  headerItems: { label: string, target: string, i18n: string }[] = [
    {
      label: 'O servisu',
      target: 'module-b',
      i18n: '@@PrestoLink_headerItem_about_service'
    }, {
      label: 'Kako se koristi',
      target: 'module-d',
      i18n: '@@PrestoLink_headerItem_how_to_use'
    }, {
      label: 'Cijena',
      target: 'module-j',
      i18n: '@@PrestoLink_headerItem_pricing'
    }, {
      label: 'Česta Pitanja',
      target: 'module-f',
      i18n: '@@PrestoLink_headerItem_faq'
    }
  ];

  menuVisible: boolean = false;


  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private router: Router,
    private typeformService: TypeformService
  ) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      document.querySelector('body').classList.remove('overflow-hidden');
    }
  }

  toggleMenu(): void {
    this.menuVisible = !this.menuVisible;

    if (isPlatformBrowser(this.platformId)) {
      document.querySelector('body').classList.toggle('overflow-hidden');
    }
  }

  closeMenu(): void {
    this.menuVisible = false;

    document.querySelector('body').classList.remove('overflow-hidden');
  }

  handleLogoClick(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if (this.router.url !== '/link') {
      this.router.navigate(['/link']).then(r => {
        window.scrollTo(0, 0);
        this.closeMenu();
      });
    } else {
      this.scrollToTop();
    }
  }

  handleHeaderItemClick(target: string): void {
    if (this.router.url !== '/link') {
      this.router.navigate(['/link']).then(r => {
        this.scrollTo(target);
      });
    } else {
      this.scrollTo(target);
    }
  }

  scrollTo(target: string): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const element: HTMLElement = document.getElementById(target);
    if (element) {
      element.scrollIntoView({behavior: 'smooth'});
    }

    this.closeMenu();
  }

  scrollToTop(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({behavior: 'smooth', top: 0});

      this.closeMenu();
    }
  }

  openTypeform(): void {
    this.typeformService.openTypeform();

    this.closeMenu();
  }

}
