<section id="module-f" class="w-full sm:container sm:px-0">
  <div class="w-full md:w-22/24 md:ml-1/24 px-2/24 pt-14 pb-19 bg-gray-50 sm:rounded-5">
    <div class="container sm:px-0 w-full text-center mb-10">
      <h2 class="font-poppins font-800 text-gray-900 text-32 sm:text-34 leading-150 sm:leading-140" i18n="@@moduleF_title">
        Česta pitanja
      </h2>
    </div>
    <div *ngFor="let faq of faqs, let i = index;" class="w-full scroll-fade">
      <button (click)="toggle(i)"
              class="container w-full py-4 sm:px-4 flex items-center justify-between text-start">
        <span class="text-gray-900 text-18 leading-164 font-700 transition-colors ease-linear"
              [ngClass]="{'text-blue-500': isOpen[i]}">
          {{ faq.title }}
        </span>
        <div class="shrink-0 w-6 h-6 border-2 border-black rounded-full relative"
             [ngClass]="{'border-blue-500': isOpen[i]}">
          <div class="absolute top-[9px] left-1 w-3 h-[2px] bg-black transition-colors duration-400 ease-linear" [ngClass]="{'bg-blue-500': isOpen[i]}"></div>
          <div class="absolute left-[9px] top-1 h-3 w-[2px] bg-black transition-[transform,background-color,opacity] ease-linear" [ngClass]="{'bg-blue-500 opacity-0': isOpen[i],
                       'rotate-90': isOpen[i] && i % 2 === 0,
                       '-rotate-90': isOpen[i] && i % 2 !== 0}">
          </div>
        </div>
      </button>

      <div class="container w-full pl-1/24 sm:pr-3/24 overflow-hidden transition-[max-height] duration-500 text-16 leading-150 border-b border-gray-100"
           [ngClass]="{'ease-out max-h-0': !this.isOpen[i], 'ease-in max-h-100': this.isOpen[i]}">
        <div class="w-full pt-2 pb-[26px]">
          {{ faq.description }}
        </div>
      </div>
    </div>
  </div>
</section>

