<div class="container pt-24 sm:pt-30 pb-20 sm:pb-30 flex flex-col gap-20">
  <div class="w-full sm:w-22/24 md:w-14/24 sm:ml-1/24 md:ml-5/24">

    <div id="terms_and_conditions" class="container">
      <h1 i18n="@@terms_and_conditions">
        Uslovi korišćenja
      </h1>

      <h2 i18n="@@terms_title1">
        ("Uslovi")
      </h2>
      <p>
        Ovi Uslovi, sa svim potencijalnim izmjenama, odnose se na korišćenje platforme PrestoLink® – softverskog rješenja za online naplatu platnim karticama Visa™ i Mastercard™ putem generisanih linkova za plaćanje.
      </p>
      <p>
        Pristupom, otvaranjem naloga ili korišćenjem naše platforme potvrđujete i slažete se da ste pročitali, razumjeli i složili se sa doljenavedenim uslovima i odredbama. Prihvatanjem ovih uslova potvrđujete da ste saglasni sa činjenicom da usluge kompanije PrestoPay, koristite isključivo za ličnu upotrebu i na sopstvenu odgovornost.
      </p>
      <p>
        Sve izmjene Uslova će biti pravovremeno objavljene na sajtu servisa (www.prestopay.me/link), sa primjerenim rokom stupanja na snagu od dana objavljivanja, te su Trgovci i Kupci dužni da provjeravaju i da budu upoznati sa svim izmjenama Uslova.
      </p>

      <h2 i18n="@@terms_definitions">
        Definicije
      </h2>
      <p>
        "PrestoPay", "mi" ili "naš" označava društvo sa ograničenom odgovornošću PrestoPay, sa sjedištem u Podgorici, koje je registrovano u skladu sa zakonima Crne Gore kod Centralnog registra privrednih subjekata Crne Gore.
      </p>
      <p>
        "Platforma" podrazumijeva izvorna softverska rješenja/sajtove PrestoLink preko kojih PrestoPay omogućava korisnicima Platforme kreiranje Online ponuda i slanje naloga za plaćanje putem linka.
      </p>
      <p>
        “Online ponuda” - je pojedinačna web stranica koju uređuje Trgovac prilikom kreiranja online ponuda, koristeći se Platformom PrestoPay, preko koje prezentuje i nudi na prodaju svoje, ili proizvode ili usluge trećih lica kupcima, uz mogućnost plaćanja karticom.
      </p>
      <p>
        “Trgovac” ili „Trgovci“ je pravno ili fizičko lice, registrovano u skladu sa važećim zakonima Crne Gore za obavljanje privredne djelatnosti i koje preko Platforme kreira ponude i naloge za plaćanje za online prodaju sopstvenih ili proizvoda trećih lica.
      </p>
      <p>
        “Kupac” je pravno ili fizičko lice koje obavlja kupovinu proizvoda koje nudi Trgovac putem linkova za plaćanje kreiranih u okviru servisa PrestoLink.
      </p>

      <h2 i18n="@@terms_types_of_service">
        1. Opseg i vrste usluga
      </h2>
      <p>
        PrestoPay, putem Platforme, nudi Trgovcu mogućnost samostalnog kreiranja ponuda i slanja naloga za plaćanje Kupcima, u vidu linka. Trgovac ima mogućnost da kreira ponude koje može prodavati putem javnog linka (više Kupaca prateći isti link može da kupi proizvod/uslugu), i/ili posebne ponude samo za određene Kupce, shodno njihovim potrebama. Otvaranjem linka, Kupac ima mogućnost da pogleda ponudu i plati online, nekom od Visa™ i Mastercard™ platnih kartica.
      </p>
      <p>
        Trgovci koji oglašavaju, promovišu i prodaju proizvode putem Online ponuda, su dužni da tačno i pravovremeno objavljuju i ažuriraju sve cijene/naknade/troškove, raspoloživosti, pravila i uslove, kao i druge relevantne informacije o proizvodima i/ili servisima koje se prikazuju putem generisanog linka. Trgovci su isključivo odgovorni da su sve navedene informacije tačne, potpune i istinite, te PrestoPay nije niti može biti odgovorna za bilo kakve greške (uključujući očigledne i slovne greške), netačne, neistinite ili informacije koje dovode u zabludu, ili pak, nedostatak informacija. Platforma PrestoPay nije i ne smije da se smatra preporukom niti potvrdom kvaliteta, nivoa usluge, kvalifikacije ili ocjene bilo kojeg raspoloživog proizvoda koji nudi Trgovac.
      </p>
      <p>
        Trgovcima, Kupcima niti bilo kojim trećim licima nije dozvoljeno da dubinski povezuju (deep-link), koriste, kopiraju, prate (npr. putem veb spajdera ili veb skrejpinga), prikazuju, preuzimaju ili reprodukuju bilo koji sadržaj ili informacije, softver, proizvode ili usluge koje su na raspolaganju na Platformi PrestoPay u bilo kakve konkurentske aktivnosti ili druge svrhe.
      </p>

      <h2 i18n="@@terms_content_of_online_offer">
        2. Sadržaj Online ponude
      </h2>
      <p>
        Trgovac je odgovoran za sadržaj koji objavljuje i prezentuje u okviru Online ponude, što uključuje ali ne ograničava se na tekst, fotografiju, video, audio, kôd, računarski softver, predmete na prodaju ili drugi materijal ("Sadržaj"), te PrestoPay nije odgovorna za bilo kakvu upotrebu ili efekte takvog Sadržaja. Tako, na primjer:
      </p>
      <ul>
        <li>
          Korišćenjem Platforme PrestoPay od strane Trgovca, PrestoPay ne podržava Sadržaj naveden u okviru Online ponude, niti predstavlja da je Sadržaj koji je objavljen tačan, koristan ili neškodljiv;
        </li>
        <li>
          Bilo koji proizvod koji je predstavljen i ponuđen na prodaju u okviru Online ponude ili objavljeni Sadržaj isključiva je odgovornost Trgovca, i Kupac može pozivati na odgovornost isključivo Trgovca u slučaju bilo kakve štete nastale kupovinom ili korišćenjem proizvoda/Sadržaja;
        </li>
        <li>
          Trgovac je odgovoran za komunikaciju sa Kupcima, u vezi prodaje proizvoda putem u okviru Online ponude, te sve žalbe, zahtjevi i reklamacije Kupaca se direktno upućuju Trgovcima. PrestoPay nije strana niti će biti odgovorna za bilo kakve komunikacije, transakcije, interakcije ili sporove između Kupca i Trgovca.
        </li>
      </ul>
      <p>
        Trgovci su isključivo odgovorni za vrstu i kvalitet proizvoda koje nude putem Online ponude, te objavljivanjem istih, garantuju za vrstu i kvalitet proizvoda u skladu sa ponudom. Trgovci su dužni da nude proizvode na prodaju u skladu sa svim važećim zakonskim propisima Crne Gore. Trgovci su isključivo odgovorni za garancije proizvoda koje nude putem linka u okviru Online ponude, kao i za eventualne zahtjeve reklamacija proizvoda od strane Kupaca.
      </p>
      <p>
        Formiranje cijena (uključujući redovne cijene i eventualne popuste) svakog pojedinačnog proizvoda i/ili usluge je u potpunoj nadležnosti Trgovca, i u istu su uključeni PDV i svi drugi porezi i naknade, osim ukoliko nije drugačije naznačeno.
      </p>
      <p>
        U cilju izbjegavanja svake sumnje, PrestoPay ni u kom slučaju nije odgovorna za vrstu i kvalitet proizvoda i/ili usluga koje Trgovci nude putem linka u okviru Online ponude, formirane cijene i eventualne popuste na proizvode kao ni za reklamacije proizvoda, ni prema Trgovcima, Kupcima ni prema trećim licima.
      </p>
      <p>
        Objavljivanjem fotografija/slika i pisanog materijala u okviru Online ponuda, Trgovac potvrđuje i garantuje da posjeduje autorska prava na fotografiji/slici i pisanom materijalu. PrestoPay zadržava pravo da na osnovnom sajtu Platforme pomene Trgovca koji koristi njeno rješenje (logo, po potrebi praćen imenom) i uspostavi link prema webstranici Trgovca, ukoliko isti postoji.
      </p>
      <p>
        Dodavanjem fotografija/slika i pisanog materijala prilikom kreiranja ponuda, Trgovac preuzima punu pravnu i moralnu odgovornost za sve zahtjeve trećih strana koji proizilaze iz korišćenja ovih fotografija/ slika od strane PrestoPay. PrestoPay ne posjeduje i ne preporučuje fotografije/slike i pisani materijal koje su objavljeni u okviru Online ponuda. Vjerodostojnost, tačnost tvrdnji a pravo na korišćenje fotografija/slika i pisanog materijala posjeduje Trgovac koji ih je dodao. Trgovac koji objavljuje fotografije/slike i pisani materijal, garantuje da fotografije/slike i pisani materijal ne sadrži viruse, trojance, da nijesu u pitanju zaraženi dokumenti i da neće sadržati pornografski, ilegalni, nepristojni, uvredljivi, nedozvoljen ili neprikladni materijal i da ne krše prava (prava intelektualne svojine, autorska prava ili prava na privatnost) trećih strana. Svaku fotografiju/sliku, pisani materijal i drugi sadržaj koji ne ispunjava navedene kriterijume, PrestoPay može izbrisati/ukloniti u svakom trenutku i bez najave.
      </p>

      <h2 i18n="@@terms_protection_of_personal_data">
        3. Zaštita podataka o ličnosti
      </h2>
      <p>
        Trgovac se pristupom, otvaranjem naloga i korišćenjem Platforme izričito saglašava sa tim da PrestoPay prikuplja i obrađuje dostavljene lične podatke Trgovca (kao i zaposlenih kod Trgovca ukoliko su isti dostavljeni od strane Trgovca, o čemu je Trgovac obavezan pribaviti pisanu saglasnost od strane zaposlenih), a u cilju obrade svih zahtjeva Trgovca i dalje komunikacije sa istima. U slučaju da PrestoPay pretrpi štetu zbog nepribavljanja svih potrebnih saglasnosti od strane Trgovca, u skladu sa Zakonom o zaštiti podataka o ličnosti Crne Gore, Trgovac će joj nadoknaditi svu štetu. Trgovac je obavezan da pribavi izričitu saglasnost Kupaca, za prikupljanje i obradu ličnih podataka te da u svemu postupa u skladu sa Zakonom o zaštiti podataka o ličnosti Crne Gore.
      </p>

      <h2 i18n="@@terms_ways_to_pay">
        4. Načini plaćanja u okviru PrestoLink servisa
      </h2>
      <p>
        Kupac može izvršiti plaćanje putem linka platnim karticama - VISA™ ili MasterCard™ koje podržavaju plaćanje preko Interneta. Plaćanje karticama u okviru PrestoLink servisa, koje je u vlasništvu PrestoPay d.o.o, realizovano je u saradnji sa
        crnogorskim komercijalnim bankama i obavlja se na bezbjedan i sertifikovan način preko Paymet Gateway-a, jednostavnim unosom podataka sa platne kartice. Nakon što se unesu podaci o kartici i potvrdi plaćanje, transakcija će biti kompletirana i iznos skinut sa računa Kupca u roku od 24h nakon izvršenog plaćanja na stranicama PrestoLink servisa.
      </p>

      <h2 i18n="@@terms_security_of_card_payment">
        5. Sigurnost plaćanja platnim karticama
      </h2>
      <p>
        Tajnost podataka Kupca je zaštićena i osigurana upotrebom SSL enkripcije. Stranice za naplatu putem interneta osigurane su korišćenjem Secure Socket Layer (SSL) protokola sa 256-bitnom enkripcijom podataka. SSL enkripcija je postupak šifriranja podataka radi sprečavanja neovlašćenog pristupa prilikom njihovog prenosa. Time je omogućen siguran prenos informacija, te onemogućen nedozvoljen pristup podacima prilikom komunikacije između korisnikovog računara i Payment Gateway servisa, i obratno.
      </p>
      <p>
        Payment Gateway servis i finansijske ustanove razmjenjuju podatke upotrebom virtuelne privatne mreže (VPN), koja je zaštićena od neautorizovanog pristupa. Payment Gateway je sertifikovan prema PCI DSS sigurnosnom standardu propisanom Visa i Mastercard pravilima.
      </p>

      <h2 i18n="@@terms_protection_of_data_during_card_payment">
        6. Zaštita podataka prilikom plaćanja karticama
      </h2>
      <p>
        Prilikom unošenja podataka o platnoj kartici, povjerljive informacije se prenose putem javne mreže u zaštićenoj (kriptovanoj) formi upotrebom SSL protokola, primjenom najsavremenijih metoda tokenizacije osjetljivih podataka, i u skladu sa PCI-DSS standardima. Niti jednog trenutka podaci o platnoj kartici nijesu dostupni Trgovcu. PrestoPay d.o.o. nema pristup brojevima kreditnih kartica i brojevi nijesu dostupni neovlašćenim osobama.
      </p>
      <p>
        3D Secure zaštita za sve trgovce i kupce - Payment Gateway koristi najviše globalne standarde zaštite i privatnosti podataka. Svi trgovci koji koriste Payment Gateway su automatski uključeni u 3D-Secure zaštitu, čime se kupcima garantuje bezbjednost kupovine. Brojevi platnih kartica kupaca se ne čuvaju na sistemu trgovca a sami upis se štiti SSL enkripcijom podataka.
      </p>
      <p>
        PCI DSS Standardi - Payment Gateway se konstantno usaglašava sa svim zahtjevima kartičarskih organizacija u cilju povećanja nivoa bezbjednosti trgovaca i kupaca.
      </p>
      <p>
        PCI Data Security Standard (PCI-DSS) je norma koja definiše neophodne mjere bezbjednosti pri obradi, čuvanju i prenošenju osjetljivih kartičarskih podataka. PCI Standardima se štite osjetljivi podaci o korisniku kartice tokom cijelog procesa plaćanja: od momenta unosa podataka na prodajnom mestu trgovca, tokom komunikacija između trgovca i relevantnih banaka i kartičarskih organizacija, kao i kasnije čuvanje tih podataka.
      </p>

      <h2 i18n="@@terms_exclusion_of_liability">
        7. Isključenje odgovornosti
      </h2>
      <p>
        PrestoPay, kao i njeni zaposleni, predstavnici, partnerske kompanije, licencirani predstavnici, agenti PrestoPay ili druga lica koja učestvuju u stvaranju, izradi, promociji ili koja na drugi način čine Platformu i Online ponude i sadržaj dostupnim, nijesu materijalno odgovorni za (i) bilo kakve kaznene, posebne, indirektne ili posljedične štete ili gubitke, gubitak proizvodnje, gubitak profita, gubitak prihoda, gubitak ugovora, štetu ili gubitak ugleda i reputacije, gubitak prava na odštetu, (ii) netačnost (opisnih) podataka (uključujući cijene i raspoloživost) o Trgovcima prikazanih u Online ponudi, (iii) proizvode ponuđene od strane Trgovca, (iv) bilo kakve (direktne, indirektne, posljedične ili kaznene) štete, gubitke ili troškove koje Trgovac ili Kupac pretrpi ili plati usljed ili u vezi sa korišćenjem, nemogućnošću korišćenja ili kašnjenjem isporuke, ili (v) za bilo kakvu (ličnu) povredu, oštećenje imovine ili druge (direktne, indirektne, posebne, posljedične ili kaznene) štete, gubitke ili troškove koje Kupac pretrpi ili plati, bilo usljed zakonskih ili drugih akata, grešaka, kršenja, (grubog) nehata, prekršaja, skrivanja informacija, nečinjenja, lažnog predstavljanja, krivičnog djela ili prekršaja koji se (djelimično ili u cjelosti) mogu pripisati Trgovcu (kao i njegovim zaposlenima, direktorima, službenicima, predstavnicima ili partnerskim kompanijama), čiji su proizvodi dostupni ili se nude i promovišu preko linka u okviru Online ponuda, uključujući i bilo kakvo (djelimično) otkazivanje, višu silu ili bilo koji drugi događaj van kontrole.
      </p>
      <p>
        PrestoPay nije odgovorna (i ne snosi nikakvu odgovornost) za korišćenje, ispravnost, kvalitet, prikladnost, formu i objavu proizvoda i usluga Trgovca dostupnih putem linka u okviru Online ponude, i ne daje nikakve izjave, garancije ili uslove sa istim, bilo da su podrazumijevani, statutarni ili neke druge vrste, uključujući i sve podrazumijevane garancije tržišnog potencijala, naslova, nekršenja prava ili prikladnosti za određenu namjenu. Ukoliko je PrestoPay (pre)prodavac proizvoda ili usluga koji se nude putem linka u okviru Online ponude Trgovca, PrestoPay ne snosi nikakvu odgovornost u vezi sa pritužbama i zahtjevima za proizvod/uslugu i prenosi ih na Trgovca.
      </p>

      <h2 i18n="@@terms_intelectual_property_rights">
        8. Prava na intelektualnu svojinu
      </h2>
      <p>
        Softversko rješenje/algoritam Platforme, a koja uključuje i softverska rješenja za plaćanje putem linka, kao i prava intelektualne svojine (uključujući autorska prava) koja se odnose na sadržaj i informacije kao i materijal na Platformi i baze podataka, u isključivom su vlasništvu PrestoPay.
      </p>
      <p>
        PrestoPay zadržava vlasništvo nad svim pravima intelektualne svojine na softveru, algoritmu, žigovima, dizajnu (uključujući infrastrukturu) Platforme na kojoj nudi usluge kreiranja online ponuda i naplate putem linka i Trgovci, Kupci i treća lica nemaju pravo da kopiraju ili djelimično preuzimaju, postavljaju hiper linkove i linkove po dubini platforme. Bilo kakvo nezakonito korišćenje Platforme ili korišćenje suprotno ovim Uslovima smatraće se povredom naših prava intelektualne svojine, pod prijetnjom materijalne i krivične odgovornosti za takvog korisnika.
      </p>

      <h2 i18n="@@terms_dealer_warranty">
        9. Garancije Trgovca
      </h2>
      <p>
        Trgovac garantuje da će koristiti Platformu i Online ponude (linkove za predstavljanje i naplatu proizvoda i usluga online), i to:
      </p>
      <ul>
        <li>
          u skladu sa ovim Uslovima i posebnim Ugovorom zaključenim između Trgovca i PrestoPay;
        </li>
        <li>
          u skladu sa svim važećim zakonima i podzakonskim aktima Crne Gore (uključujući ali ne ograničavajući se na propise koji regulišu zaštitu potrošača, zaštitu ličnih podataka, privredna društva i obavljanje privredne djelatnosti itd.);
        </li>
        <li>
          da neće koristiti Online ponude u nezakonite svrhe, da neće objavljivati nezakonit sadržaj niti se baviti nezakonitim radnjama;
        </li>
        <li>
          da neće povrijediti ili zloupotrijebiti prava intelektualne svojine ili bilo koja druga prava trećih strana;
        </li>
        <li>
          da neće koristiti istu za kreiranje, distribuciju ili omogućavanje materijala koji olakšava ili djeluje u kombinaciji sa - zlonamjernim softverom ili drugim zlonamjernim programima ili kodom.
        </li>
      </ul>
      <p>
        Trgovac garantuje da je registrovan u skladu sa zakonima Crne Gore za obavljanje određene privredne djelatnosti, kao i da ima sve potrebne dozvole i odobrenja za obavljanje privredne djelatnosti. Trgovac koji reklamira i prodaje proizvode ili usluge trećih lica, putem generisanog linka, garantuje da su i Trgovac i treće lice registrovani i da imaju sve potrebne dozvole i odobrenja za obavljanje privredne djelatnosti.
      </p>

      <h2 i18n="@@terms_others">
        10. Ostale odredbe
      </h2>
      <p>
        Ovi uslovi i odredbe i njihovo tumačenje, kao i pružanje usluga PrestoPay, podliježu i u saglasnosti su sa zakonima Crne Gore. Trgovac zaključuje Ugovor o pružanju usluga sa PrestoPay-om, te su i Ugovor i Uslovi pravno obavezujući za Trgovca. U slučaju neslaganja Uslova i Ugovora, prevagu ima zaključeni Ugovor između Trgovca i PrestoPay. Ukoliko je bilo koja tačka ovih Uslova sada ili ubuduće nevažeća, neprimjenljiva ili neobavezujuća, i dalje će važiti sve ostale tačke.
      </p>
    </div>
  </div>
</div>
