import { Component } from '@angular/core';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { TypeformService} from '../../../../services/typeform.service';

@Component({
  selector: 'app-presto-link-module-e',
  templateUrl: './presto-link-module-e.component.html'
})
export class PrestoLinkModuleEComponent {

  faCheck = faCheck;

  constructor(private typeformService: TypeformService) {
  }

  openTypeform(): void {
    this.typeformService.openTypeform();
  }

}
