import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './layouts/not-found/not-found.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {PrestoLinkModule} from './@modules/presto-link/presto-link.module';
import {PrestoLinkRoutingModule} from './@modules/presto-link/presto-link-routing.module';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'presto-website'}),
    AppRoutingModule,
    FontAwesomeModule,
    PrestoLinkModule,
    PrestoLinkRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
