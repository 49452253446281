import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { SeoService } from './services/seo/seo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private seoService: SeoService
  ) {
  }


  ngOnInit(): void {
    this.seoService.init();
  }
}
