import { Component } from '@angular/core';
import { faBrowser, faFileLines, faMessages, faChartSimple } from '@fortawesome/pro-duotone-svg-icons';

@Component({
  selector: 'app-presto-link-module-c',
  templateUrl: './presto-link-module-c.component.html'
})
export class PrestoLinkModuleCComponent {

  faBrowser = faBrowser;
  faFileLines = faFileLines;
  faMessages = faMessages;
  faChartSimple = faChartSimple;

  constructor() {
  }

}
