import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-presto-link-module-b',
  templateUrl: './presto-link-module-b.component.html'
})
export class PrestoLinkModuleBComponent implements OnInit {

  mediaUrl: string = environment.mediaUrl;

  constructor() {
  }

  ngOnInit(): void {
  }

}
