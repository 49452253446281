import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { SeoItem } from './seo-item.model';
import { RouteName } from '../../routes';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { LocaleService } from '../locale.service';

@Injectable({
  providedIn: 'root'
})
export class SeoRichDataService {

  private defaultText: string = '';
  private currentRoute: RouteName = null;
  private currentObject: any = null;
  private seoItem: SeoItem = null;
  private link: HTMLLinkElement;
  private ouTitle: string;

  constructor(
    private localeService: LocaleService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private locale: string
  ) {
    const logoData: any = {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      url: environment.websiteUrl + '/assets/img/logos/logo.svg'
    };

    const structuredDataScript = this.document.createElement('script');
    structuredDataScript.setAttribute('type', 'application/ld+json');
    structuredDataScript.setAttribute('id', 'logo');
    structuredDataScript.innerHTML = JSON.stringify(logoData);
    this.document.head.appendChild(structuredDataScript);
  }

  setCurrentRoute(currentRoute: RouteName): void {
    this.currentRoute = currentRoute;
  }

  setDefaultText(defaultText: string): void {
    this.defaultText = defaultText;
  }

  setCurrentObject(currentObject): void {
    this.currentObject = currentObject;
  }

  setSeoItem(seoItem: SeoItem): void {
    this.seoItem = seoItem;
  }

  reset(): void {
    this.setCurrentObject(null);
    this.setSeoItem(null);
    this.setCurrentRoute(null);
    this.removeRichData();
  }

  removeRichData(): void {
    const richData = this.document.querySelectorAll('[type="application/ld+json"]');
    richData.forEach((richElement) => {
      if (richElement.getAttribute('id') !== 'logo') {
        richElement.remove();
      }
    });
  }

  refresh(): void {
    this.setHomepageStructuredData();
  }

  setHomepageStructuredData(): void {
    if (this.currentObject && this.currentRoute === RouteName.HOMEPAGE) {

      if (this.currentObject.organizational_unit) {
        this.ouTitle = this.currentObject.organizational_unit.title;
      } else {
        this.ouTitle = 'PrestoLink®';
      }

      const structuredPageData: any = {
        '@context': 'https://schema.org',
        '@type': 'WebPage',
        name: this.currentObject.seo_title,
        description: this.currentObject.seo_description,
        image: [
          environment.mediaUrl + '/' + this.currentObject.seo_image_url
        ],
        publisher: {
          '@type': 'Organization',
          name: this.ouTitle,
          logo: {
            '@type': 'ImageObject',
            url: environment.websiteUrl + '/assets/img/logos/logo.svg',
            width: 119,
            height: 17
          }
        }
      };

      const structuredPageDataScript = this.document.createElement('script');
      structuredPageDataScript.setAttribute('type', 'application/ld+json');
      structuredPageDataScript.innerHTML = JSON.stringify(structuredPageData);
      this.document.head.appendChild(structuredPageDataScript);
    }
  }
}
