<section id="module-b" class="container flex flex-col-reverse sm:flex-row gap-y-16">
  <div class="w-full sm:w-12/24 md:pl-3/24 scroll-fade">
    <picture class="w-full">
      <source type="image/webp" srcset="{{ mediaUrl }}/img/modules/module_b.webp">
      <img src="{{ mediaUrl }}/img/modules/module_b.png" alt="graphics" width="458" height="454">
    </picture>
  </div>
  <div class="w-full sm:w-12/24 md:pr-3/24 flex flex-col justify-center gap-5 sm:gap-8">
    <h2 class="text-30 sm:text-34 leading-122 font-poppins font-800 sm:-mr-1 scroll-fade" i18n="@@moduleB_title">
      Potpuno integrisani sistem za online plaćanje!
    </h2>
    <span class="text-400 text-16 sm:text-18 leading-137 sm:leading-150 md:pr-[4.5rem] scroll-fade">
      <span i18n="@@moduleB_description1">
        Ukoliko želite da brzo, jednostavno, bez tehničkih integracija i uz minimum papirologije omogućite svojim kupcima online plaćanje,
      </span>
      <span class="font-800 inline-block" i18n="@@PrestoLink">PrestoLink</span>
      <span>®</span>
      <span i18n="@@moduleB_description2">
        je pravo rješenje za vas!
      </span>
    </span>
  </div>
</section>
