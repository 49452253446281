import { Component, OnInit } from '@angular/core';
import { environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-presto-link-module-d',
  templateUrl: './presto-link-module-d.component.html'
})
export class PrestoLinkModuleDComponent implements OnInit {

  mediaUrl: string = environment.mediaUrl;

  constructor() {
  }

  ngOnInit(): void {
  }

}
