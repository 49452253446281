<section class="w-full sm:container sm:px-0 sm:pb-22">
  <div class="bg-gray-900 sm:rounded-6 pt-18 pb-26 text-white">
    <div class="container sm:px-0 w-full flex flex-col sm:flex-row sm:justify-between gap-y-10">
      <div class="w-full sm:w-9/24 md:w-7/24 sm:ml-2/24 flex flex-col gap-8 sm:gap-11">
        <div class="order-2 sm:order-1">
          <span class="scroll-fade block mt-8 sm:mt-11 text-30 sm:text-32 leading-125 font-poppins font-800" i18n="@@footer_title">
            Servis za online plaćanje putem linka
          </span>
        </div>
        <button (click)="handleLogoClick()" class="scroll-fade order-1 sm:order-2">
          <img src="assets/img/logos/prestolink-logo-light-sm.png" alt="PrestoLink" width="134" height="36" title="Nazad na početnu" i18n-title="@@back_to_homepage">
        </button>
      </div>
      <div class="w-full sm:w-6/24 md:w-5/24 sm:mr-2/24 flex flex-col sm:justify-end gap-4 font-400 text-14 sm:text-16 leading-150">
        <span class="scroll-fade flex items-center gap-4">
          <fa-icon [icon]="faEnvelope" class="text-24"></fa-icon>
          <a href="sales@prestopay.me" target="_blank" title="Pošaljite nam E-mail" i18n="@@send_us_an_email"
             class="underline decoration-transparent hover:decoration-white transition-colors duration-200 ease-linear">sales@prestopay.me</a>
        </span>
        <span class="scroll-fade flex items-center gap-4">
          <fa-icon [icon]="faCirclePhone" class="text-24"></fa-icon>
          <a href="tel:+382-67-156-516" target="_blank" title="Pozovite nas" i18n-title="@@call_us"
             class="underline decoration-transparent hover:decoration-white transition-colors duration-200 ease-linear">+ 382 67 156 516</a>
        </span>
        <span class="scroll-fade flex items-center gap-4">
          <fa-icon [icon]="faLocationDot" class="text-24"></fa-icon>
          <a href="https://goo.gl/maps/Anrekrk8KgyNA7CY6" target="_blank" title="Pronađite nas" i18n-title="@@find_us"
             class="underline decoration-transparent hover:decoration-white transition-colors duration-200 ease-linear">Podgorica, Montenegro</a>
        </span>
      </div>
    </div>

    <div class="container sm:w-20/24 sm:mx-2/24 mt-10 sm:mt-24 w-full pt-4 border-t border-gray-700 flex flex-col-reverse sm:flex-row sm:justify-between gap-y-4 text-14 sm:text-16 leading-150">
      <span class="scroll-fade">
        © {{ today | date: 'yyyy' }}
        <span
           class="decoration-transparent transition-colors duration-200 ease-linear">
          PrestoPay doo.
        </span>
        <span i18n="@@footer_all_rights_reserved_dot_full_stop">
          Sva prava zadržana.
        </span>
      </span>
      <a [routerLink]="['uslovi-koriscenja']"
         title="Uslovi korišćenja"
         i18n-title="@@terms-and-conditions"
         class="scroll-fade underline"
         i18n="@@footer_terms_and_conditions"
         (click)="scrollToTop()">
        Uslovi korišćenja
      </a>
    </div>
  </div>
</section>
