import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-presto-link-module-a',
  templateUrl: './presto-link-module-a.component.html',
  styleUrls: ['./presto-link-module-a.component.scss']
})
export class PrestoLinkModuleAComponent implements OnInit {

  mediaUrl: string = environment.mediaUrl;
  isMobileLayout: boolean = null;

  constructor(
    @Inject(PLATFORM_ID) private platformId
  ) {
  }

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.isMobileLayout = window.innerWidth <= 768;

    window.addEventListener('resize', () => {
      this.isMobileLayout = window.innerWidth <= 768;
    });
  }

  scrollTo(target: string): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const element = document.getElementById(target);
    if (element) {
      element.scrollIntoView({behavior: 'smooth'});
    }
  }

}
