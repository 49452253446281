import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-presto-link-module-i',
  templateUrl: './presto-link-module-i.component.html'
})
export class PrestoLinkModuleIComponent implements OnInit {

  isMobileLayout: boolean = null;

  constructor(@Inject(PLATFORM_ID) private platformId) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isMobileLayout = window.innerWidth <= 768;

      window.addEventListener('resize', () => {
        this.isMobileLayout = window.innerWidth <= 768;
      });
    }
  }

}
