<section id="module-i" class="container">
  <div *ngIf="!isMobileLayout" class="w-full md:w-18/24 md:ml-3/24 flex flex-row items-center justify-between">
    <img src="assets/img/logos/fleka.png" width="84" height="43" alt="fleka" class="scroll-fade">
    <img src="assets/img/logos/ckb.png" width="91" height="83" alt="CKB" class="scroll-fade">
    <img src="assets/img/logos/nlb.png" width="100" height="24" alt="NLB Banka" class="scroll-fade">
    <img src="assets/img/logos/allsecure.png" width="120" height="43" alt="AllSecure" class="scroll-fade">
    <img src="assets/img/logos/wspay.png" width="111" height="48" alt="Web Secure Payment Gateway" class="scroll-fade">
    <img src="assets/img/logos/visa_grayscale.png" width="103" height="33" alt="VISA" class="scroll-fade">
    <img src="assets/img/logos/mastercard_grayscale.png" width="81" height="63" alt="mastercard" class="scroll-fade">
  </div>
  <div *ngIf="isMobileLayout" class="ml-3/24 w-18/24 flex flex-row items-center justify-between">
    <div class="flex flex-col items-center gap-12">
      <img src="assets/img/logos/fleka.png" width="84" height="43" alt="fleka" class="scroll-fade">
      <img src="assets/img/logos/ckb.png" width="74" height="67" alt="CKB" class="scroll-fade">
          <img src="assets/img/logos/nlb.png" width="100" height="24" alt="NLB Banka" class="scroll-fade">
      <img src="assets/img/logos/allsecure.png" width="120" height="43" alt="AllSecure" class="scroll-fade">
    </div>
    <div class="flex flex-col items-center gap-12">
      <img src="assets/img/logos/wspay.png" width="111" height="48" alt="Web Secure Payment Gateway" class="scroll-fade">
      <img src="assets/img/logos/visa_grayscale.png" width="85" height="27" alt="VISA" class="scroll-fade">
      <img src="assets/img/logos/mastercard_grayscale.png" width="67" height="52" alt="mastercard" class="scroll-fade">
    </div>
  </div>
</section>
