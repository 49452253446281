import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';


import { PrestoLinkRoutingModule } from './presto-link-routing.module';
import { PrestoLinkComponent } from './presto-link.component';
import { PrestoLinkLayoutComponent } from './components/presto-link-layout/presto-link-layout.component';
import {PrestoLinkHeaderComponent} from './components/presto-link-header/presto-link-header.component';
import {PrestoLinkFooterComponent} from './components/presto-link-footer/presto-link-footer.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {PrestoLinkModuleAComponent} from './components/presto-link-module-a/presto-link-module-a.component';
import {PrestoLinkModuleBComponent} from './components/presto-link-module-b/presto-link-module-b.component';
import {PrestoLinkModuleCComponent} from './components/presto-link-module-c/presto-link-module-c.component';
import {PrestoLinkModuleDComponent} from './components/presto-link-module-d/presto-link-module-d.component';
import {PrestoLinkModuleEComponent} from './components/presto-link-module-e/presto-link-module-e.component';
import {PrestoLinkModuleFComponent} from './components/presto-link-module-f/presto-link-module-f.component';
import {PrestoLinkModuleGComponent} from './components/presto-link-module-g/presto-link-module-g.component';
import {PrestoLinkModuleHComponent} from './components/presto-link-module-h/presto-link-module-h.component';
import {PrestoLinkModuleIComponent} from './components/presto-link-module-i/presto-link-module-i.component';
import {PrestoLinkModuleJComponent} from './components/presto-link-module-j/presto-link-module-j.component';
import {PrestoLinkTermsComponent} from './components/presto-link-terms/presto-link-terms.component';


@NgModule({
  declarations: [
    PrestoLinkComponent,
    PrestoLinkLayoutComponent,
    PrestoLinkModuleAComponent,
    PrestoLinkModuleBComponent,
    PrestoLinkModuleCComponent,
    PrestoLinkModuleDComponent,
    PrestoLinkModuleEComponent,
    PrestoLinkModuleFComponent,
    PrestoLinkModuleGComponent,
    PrestoLinkModuleHComponent,
    PrestoLinkModuleIComponent,
    PrestoLinkModuleJComponent,
    PrestoLinkTermsComponent,
    PrestoLinkHeaderComponent,
    PrestoLinkFooterComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    PrestoLinkRoutingModule
  ]
})
export class PrestoLinkModule { }
