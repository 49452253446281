<section id="module-a" class="sm:container sm:px-0">
  <div class="w-full overflow-hidden sm:rounded-6 relative h-screen sm:h-[330px] md:h-[569px] text-white gradient-blue-100">
    <video *ngIf="!isMobileLayout" loop autoplay playsinline [muted]="true" class="w-full h-full"
           poster="assets/img/modules/module_a.webp">
      <source src="{{ mediaUrl }}/video/module_a.mp4" type="video/mp4">
    </video>
    <video *ngIf="isMobileLayout" loop [muted]="true" autoplay playsinline class="w-full h-full"
           poster="assets/img/modules/module_a_mobile.webp">
      <source src="{{ mediaUrl }}/video/module_a_mobile.mp4" type="video/mp4">
    </video>
    <div class="absolute inset-0 bg-[#000000]/50 sm:rounded-6">
      <div class="container w-full sm:w-22/24 md:w-17/24 sm:pl-1/24 md:pl-3/24 pt-[327px] sm:pt-8 md:pt-34 flex flex-col gap-6 scroll-fade">
        <h1 class="font-poppins font-800 text-38 leading-110 sm:text-42 md:text-60 sm:leading-[1.05] sm:mr-48 md:mr-60" i18n="@@moduleA_title">
          Servis za online plaćanje putem linka
        </h1>
        <span class="text-16 sm:text-18 leading-137 sm:leading-150 sm:pr-34">
          <span i18n="@@moduleA_description1">Naplatite usluge online! </span>
          <span class="font-800" i18n="@@PrestoLink">PrestoLink</span>
          <span>®</span>
          <span i18n="@@moduleA_description1">
            ne poznaje granice. Pošaljite link za plaćanje svojim kupcima, gdje god da se nalaze.
          </span>
        </span>
        <button class="self-start button-medium--dark"
                (click)="scrollTo('module-b')">
          <span i18n="@@find_out_more">
            Saznaj više
          </span>
        </button>
      </div>
    </div>
  </div>
</section>
