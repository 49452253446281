import {AfterViewInit, Component, Inject, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SeoService} from '../../services/seo/seo.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html'
})
export class HomepageComponent implements AfterViewInit {

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private activatedRoute: ActivatedRoute,
    private seoService: SeoService,
  ) { }

  ngAfterViewInit(): void {
    this.seoService.refreshForRoute(this.activatedRoute.snapshot);
  }

}
