import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { faEnvelope, faCirclePhone, faLocationDot } from '@fortawesome/pro-duotone-svg-icons';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-presto-link-footer',
  templateUrl: './presto-link-footer.component.html'
})
export class PrestoLinkFooterComponent implements OnInit {

  today: Date = new Date();

  faEnvelope = faEnvelope;
  faCirclePhone = faCirclePhone;
  faLocationDot = faLocationDot;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private router: Router
  ) {
  }

  ngOnInit(): void {
  }

  scrollToTop(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
  }

  handleLogoClick(): void {
    if (this.router.url !== '/link') {
      this.router.navigate(['/link']).then(r => {
        this.scrollToTop();
      });
    } else {
      window.scrollTo({behavior: 'smooth', top: 0});
    }
  }

}
