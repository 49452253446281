import {AfterViewInit, Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {ScrollFadeService} from '../../../../services/scroll-fade.service';
import {isPlatformBrowser} from '@angular/common';
import {SeoService} from '../../../../services/seo/seo.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-presto-link-terms',
  templateUrl: './presto-link-terms.component.html',
  styleUrls: ['./presto-link-terms.component.scss']
})
export class PrestoLinkTermsComponent implements AfterViewInit, OnDestroy {

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private activatedRoute: ActivatedRoute,
    private seoService: SeoService,
    private scrollFadeService: ScrollFadeService
  ) {
  }

  ngAfterViewInit(): void {
    this.seoService.refreshForRoute(this.activatedRoute.snapshot);

    if (isPlatformBrowser(this.platformId)) {
      this.addScrollFadeToElements();

      const fadingElements = document.querySelectorAll('.scroll-fade');
      this.scrollFadeService.applyScrollFade(fadingElements);
    }
  }

  addScrollFadeToElements(): void {
    const elements = document.querySelector('#terms_and_conditions').children;

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].tagName === 'UL') {
        const listItems: HTMLCollection = elements[i].children;

        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < listItems.length; j++) {
          listItems[j].classList.add('scroll-fade');
        }
      } else {
        elements[i].classList.add('scroll-fade');
      }
    }
  }

  ngOnDestroy(): void {
    this.scrollFadeService.disconnect();
  }

}
