import { environment } from 'src/environments/environment';
import { SeoItem } from './services/seo/seo-item.model';

export const seoRules = {
  notFound: {
    me: new SeoItem({
      title: '404 - Stranica koju tražite nije pronađena',
      description: 'Stranica koju tražite ne može se pronaći. Moguće je da je premještena ili izbrisana.' +
        'Molimo provjerite URL ili koristite navigaciju kako biste pronašli ono što tražite.',
      seo_title: '404 - Stranica koju tražite nije pronađena',
      seo_fb_title: '404 - Stranica koju tražite nije pronađena',
      seo_fb_description: 'Stranica koju tražite ne može se pronaći. Moguće je da je premještena ili izbrisana.' +
        'Molimo provjerite URL ili koristite navigaciju kako biste pronašli ono što tražite.',
      seo_fb_type: 'website',
      seo_tw_title: '404 - Stranica koju tražite nije pronađena',
      seo_tw_description: 'Stranica koju tražite ne može se pronaći. Moguće je da je premještena ili izbrisana.' +
        'Molimo provjerite URL ili koristite navigaciju kako biste pronašli ono što tražite.',
      seo_keywords: 'Presto, Montenegro',
      url: ''
    }),
    en: new SeoItem({
      title: '404 - Not Found',
      description: '404 english description',
      seo_title: '404 - Not Found',
      seo_fb_title: '404 - Not Found',
      seo_fb_description: '404 english description',
      seo_description: '404 english description',
      seo_tw_title: '404 - Not Found',
      seo_tw_description: '404 english description',
      seo_fb_type: 'website',
      seo_keywords: 'PrestoLink, Montenegro',
      url: ''
    })
  },
  homepage: {
    me: new SeoItem({
      title: 'presto®',
      description: 'presto® - Early Adopter Testing Form',
      seo_title: 'presto®',
      seo_fb_title: 'presto®',
      seo_fb_description: 'presto® - Early Adopter Testing Form',
      seo_fb_type: 'website',
      seo_tw_title: 'presto®',
      seo_tw_description: 'presto® - Early Adopter Testing Form',
      seo_keywords: 'Presto, Montenegro',
      url: ''
    }),
    en: new SeoItem({
      title: 'presto®',
      description: 'presto® - Early Adopter Testing Form',
      seo_title: 'presto®',
      seo_fb_title: 'presto®',
      seo_fb_description: 'presto® - Early Adopter Testing Form',
      seo_description: 'presto® - Early Adopter Testing Form',
      seo_tw_title: 'presto®',
      seo_tw_description: 'presto® - Early Adopter Testing Form',
      seo_fb_type: 'website',
      seo_keywords: 'Presto, Montenegro',
      url: ''
    })
  },
  prestoLink: {
    me: new SeoItem({
      title: 'PrestoLink®',
      description: 'U okviru PrestoLink® servisa sami kreirate ponudu i šaljete nalog za plaćanje vašim kupcima u vidu linka. Otvaranjem linka, kupci' +
        ' mogu da pogledaju ponudu i plate online, kao da su bili kod vas i platili karticom.',
      seo_title: 'PrestoLink®',
      seo_fb_title: 'PrestoLink®',
      seo_fb_description: 'U okviru PrestoLink® servisa sami kreirate ponudu i šaljete nalog za plaćanje vašim kupcima u vidu linka. Otvaranjem' +
        ' linka, kupci mogu da pogledaju ponudu i plate online, kao da su bili kod vas i platili karticom.',
      seo_fb_type: 'website',
      seo_fb_image_url: environment.websiteUrl + '/assets/img/seo/prestolink-seo-og.jpg',
      seo_tw_title: 'PrestoLink®',
      seo_tw_description: 'U okviru PrestoLink® servisa sami kreirate ponudu i šaljete nalog za plaćanje vašim kupcima u vidu linka. Otvaranjem' +
        ' linka, kupci mogu da pogledaju ponudu i plate online, kao da su bili kod vas i platili karticom.',
      seo_tw_image_url: environment.websiteUrl + '/assets/img/seo/prestolink-seo-tw.jpg',
      seo_keywords: 'PrestoLink, Montenegro',
      url: ''
    }),
    en: new SeoItem({
      title: 'PrestoLink®',
      description: 'PrestoLink®',
      seo_title: 'PrestoLink®',
      seo_fb_title: 'PrestoLink®',
      seo_fb_description: 'PrestoLink®',
      seo_fb_image_url: environment.websiteUrl + '/assets/img/seo/prestolink-seo-og.jpg',
      seo_description: 'PrestoLink®',
      seo_tw_title: 'PrestoLink®',
      seo_tw_description: 'PrestoLink®',
      seo_tw_image_url: environment.websiteUrl + '/assets/img/seo/prestolink-seo-tw.jpg',
      seo_fb_type: 'website',
      seo_keywords: 'PrestoLink, Montenegro',
      url: ''
    })
  },
  prestoLinkTerms: {
    me: new SeoItem({
      title: 'PrestoLink® - Uslovi korišćenja',
      description: 'PrestoLink® - Uslovi korišćenja servisa',
      seo_title: 'PrestoLink® - Uslovi korišćenja',
      seo_fb_title: 'PrestoLink® - Uslovi korišćenja',
      seo_fb_description: 'PrestoLink® - Uslovi korišćenja servisa',
      seo_fb_type: 'website',
      seo_tw_title: 'PrestoLink® - Uslovi korišćenja',
      seo_tw_description: 'PrestoLink® - Uslovi korišćenja servisa',
      seo_keywords: 'PrestoLink, Montenegro',
      url: ''
    }),
    en: new SeoItem({
      title: 'PrestoLink® - Terms and Conditions',
      description: 'PrestoLink® - Terms and Conditions',
      seo_title: 'PrestoLink® - Terms and Conditions',
      seo_fb_title: 'PrestoLink® - Terms and Conditions',
      seo_fb_description: 'PrestoLink® - Terms and Conditions',
      seo_description: '',
      seo_tw_title: 'PrestoLink® - Terms and Conditions',
      seo_tw_description: 'PrestoLink® - Terms and Conditions',
      seo_fb_type: 'website',
      seo_keywords: 'PrestoLink, Montenegro',
      url: ''
    })
  }
};
