<section id="module-c" class="container">
  <div class="w-full rounded-5 sm:rounded-6 gradient-blue-100 pt-13 sm:pt-12 pb-14 flex flex-col sm:flex-row items-center gap-y-7 text-white">
    <div class="sm:ml-2/24 md:ml-3/24 w-18/24 sm:w-8/24 flex flex-col gap-7">
      <h2 class="text-30 md:text-34 leading-122 font-poppins font-800 -mr-2 sm:mr-0 scroll-fade" i18n="@@moduleC_title">
        Uvedite jednostavan sistem za online plaćanje.
      </h2>
      <span class="text-16 md:text-18 leading-137 sm:leading-150 pr-6 sm:pr-5 md:pr-[3.75rem] scroll-fade">
        <span i18n="@@moduleC_description1">
          Servis
        </span>
        <span class="inline-block" i18n="@@PrestoLink">PrestoLink</span>
        <span>®</span>
        <span i18n="@@moduleC_description2">
          vam nudi mogućnost da kreirate online ponudu i pošaljete link za plaćanje svojim klijentima.
        </span>
      </span>
    </div>
    <div class="sm:ml-1/24 sm:mr-2/24 w-full sm:w-10/24 px-4 sm:px-0 grid grid-cols-1 md:grid-cols-2 gap-3">
      <div class="w-full rounded-4 pl-5 pr-5 md:pr-16 py-6 flex flex-row md:flex-col gap-5 sm:gap-9 scroll-fade"
           [ngStyle]="{'background-color': 'rgba(248, 248, 248, 0.19)'}">
        <div class="w-12 h-12 rounded-3 p-3 flex items-center justify-center"
             [ngStyle]="{'background-color': 'rgba(235, 239, 250, 0.2)'}">
          <fa-icon [icon]="faBrowser" class="text-24"></fa-icon>
        </div>
        <span class="text-14 sm:text-16 leading-122 font-700 pr-14 sm:pr-0" i18n="@@moduleC_box1">
          Kontrolni panel za kreiranje online ponuda.
        </span>
      </div>
      <div class="w-full rounded-4 pl-5 pr-5 md:pr-16 py-6 flex flex-row md:flex-col gap-5 sm:gap-9 scroll-fade"
           [ngStyle]="{'background-color': 'rgba(248, 248, 248, 0.19)'}">
        <div class="w-12 h-12 rounded-3 p-3 flex items-center justify-center"
             [ngStyle]="{'background-color': 'rgba(235, 239, 250, 0.2)'}">
          <fa-icon [icon]="faMessages" class="text-24"></fa-icon>
        </div>
        <span class="text-14 sm:text-16 leading-122 font-700 pr-14 sm:pr-0" i18n="@@moduleC_box2">
          Slanje linkova za plaćanje mejlom ili porukom.
        </span>
      </div>
      <div class="w-full rounded-4 pl-5 pr-5 md:pr-16 py-6 flex flex-row md:flex-col gap-5 sm:gap-9 scroll-fade"
           [ngStyle]="{'background-color': 'rgba(248, 248, 248, 0.19)'}">
        <div class="w-12 h-12 rounded-3 p-3 flex items-center justify-center"
             [ngStyle]="{'background-color': 'rgba(235, 239, 250, 0.2)'}">
          <fa-icon [icon]="faFileLines" class="text-24"></fa-icon>
        </div>
        <span class="text-14 sm:text-16 leading-122 font-700 pr-14 sm:pr-0" i18n="@@moduleC_box3">
          Uvid u status pojedinačnih uplata.
        </span>
      </div>
      <div class="w-full rounded-4 pl-5 pr-5 md:pr-16 py-6 flex flex-row md:flex-col gap-5 sm:gap-9 scroll-fade"
           [ngStyle]="{'background-color': 'rgba(248, 248, 248, 0.19)'}">
        <div class="w-12 h-12 rounded-3 p-3 flex items-center justify-center"
             [ngStyle]="{'background-color': 'rgba(235, 239, 250, 0.2)'}">
          <fa-icon [icon]="faChartSimple" class="text-24"></fa-icon>
        </div>
        <span class="text-14 sm:text-16 leading-122 font-700 pr-14 sm:pr-0" i18n="@@moduleC_box4">
          Detaljan pregled statistike svih online transakcija.
        </span>
      </div>
    </div>
  </div>
</section>
