import { AfterViewInit, Component, OnInit } from '@angular/core';
import { SeoService } from '../../services/seo/seo.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html'
})
export class NotFoundComponent implements AfterViewInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private seoService: SeoService
  ) { }

  ngAfterViewInit(): void {
    this.seoService.refreshForRoute(this.activatedRoute.snapshot);
  }

}
