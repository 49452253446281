import { AfterViewInit, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { environment} from '../../../../../environments/environment';

Swiper.use([Navigation, Pagination, Autoplay]);

@Component({
  selector: 'app-presto-link-module-h',
  templateUrl: './presto-link-module-h.component.html'
})
export class PrestoLinkModuleHComponent implements OnInit, AfterViewInit {

  mediaUrl: string = environment.mediaUrl;

  items: { title: string, url: string, url_mobile: string; gradient: string }[] = [
    {
      title: 'Online plaćanje rate za održavanje ulaza',
      url: this.mediaUrl + '/img/modules/module-h/slide1',
      url_mobile: this.mediaUrl + '/img/modules/module-h/slide1_mobile',
      gradient: 'green'
    },
    {
      title: 'Online plaćanje režija putem linka',
      url: this.mediaUrl + '/img/modules/module-h/slide2',
      url_mobile: this.mediaUrl + '/img/modules/module-h/slide2_mobile',
      gradient: 'violet'
    },
    {
      title: 'Online prodaja proizvoda i usluga',
      url: this.mediaUrl + '/img/modules/module-h/slide3',
      url_mobile: this.mediaUrl + '/img/modules/module-h/slide3_mobile',
      gradient: 'purple'
    },
    {
      title: 'Savremeno rješenje za plaćanje vrtića',
      url: this.mediaUrl + '/img/modules/module-h/slide4',
      url_mobile: this.mediaUrl + '/img/modules/module-h/slide4_mobile',
      gradient: 'orange'
    }
  ];

  swiper: Swiper;
  activeIndex: number = 0;

  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;

  constructor(@Inject(PLATFORM_ID) private platformId) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.swiper = new Swiper('.swiper-module-h', {
      modules: [Navigation],
      slidesPerView: 1,
      spaceBetween: 0,
      loop: true,
      preloadImages: false,
      lazy: true,
      autoplay: {
        delay: 5000
      },
      navigation: {
        nextEl: '.nav-right',
        prevEl: '.nav-left'
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets'
      },
      on: {
        slideChange: () => {
          if (this.swiper) {
            this.activeIndex = this.geSlideDataIndex();
          }
        }
      }
    });
  }

  geSlideDataIndex(): number {
    let activeIndex = this.swiper.activeIndex;
    const slidesLen = this.swiper.slides.length;
    if (this.swiper.params.loop) {
      switch (this.swiper.activeIndex) {
        case 0:
          activeIndex = slidesLen - 3;
          break;
        case slidesLen - 1:
          activeIndex = 0;
          break;
        default:
          --activeIndex;
      }
    }
    return activeIndex;
  }

}
