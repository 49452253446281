import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-presto-link-module-f',
  templateUrl: './presto-link-module-f.component.html'
})
export class PrestoLinkModuleFComponent implements OnInit {

  faqs: { title: string, description: string }[] = [
    {
      title: 'Šta je servis PrestoLink®?',
      description: 'PrestoLink® je servis koji omogućava jednostavnu, brzu i bezbjednu prodaju vaših usluga ili proizvoda putem interneta. U okviru' +
        ' servisa sami kreirate ponudu i šaljete nalog za plaćanje vašim klijentima u vidu linka.'
    },
    {
      title: 'Kome je namijenjen ovaj servis?',
      description: 'PrestoLink® je servis namijenjen svim pravnim licima koja žele da aktiviraju e-commerce uslugu, bez potrebe za kreiranjem' +
        ' online prodavnice. Nije važno da li vodite malu, srednju ili veliku firmu, da li prodajete proizvode ili usluge. Uz servis PrestoLink®' +
        ' imaćete brzo i pristupačno rješenje za online naplatu!'
    },
    {
      title: 'Kako funkcioniše ovaj servis?',
      description: 'U okviru servisa PrestoLink® dobijate pristup sistemu za kreiranje online ponuda koji je jednostavan i lak za korišćenje. ' +
        'Sve što je potrebno je da unesete opis proizvoda ili usluge, eventualne prateće fotografije, definišete cijenu i uslove kupovine. Na' +
        ' kreiranom linku naći će se proizvod ili usluga dostupna za online kupovinu!'
    },
    {
      title: 'Na koji način korisnik plaća putem linka?',
      description: 'Otvaranjem linka, korisnik ima mogućnost da pogleda ponudu i popuni formu za plaćanje. Transakcija se realizuje ' +
        'u svega nekoliko trenutaka, na bezbjedan i sertifikovan način, putem servisa PrestoLink® u saradnji sa ' +
        'Crnogorskom komercijalnom bankom a.d. Ne morate da čekate naplatu pouzećem. ' +
        'Korisnici, na ovaj način, mogu da plate vaše proizvode ili usluge karticom - online, gdje god da se nalaze.'
    },
    {
      title: 'Kako se pokreće servis?',
      description: 'Pokretanje online poslovanja je proces koji često zahtijeva dosta vremena i ulaganja, što je ' +
        'najčešći razlog odustajanja mnogih manjih preduzeća od ovakvog tipa usluge. Ukoliko želite da brzo i lako, ' +
        'bez tehničkih intergacija i uz minimum papirologije omogućite svojima klijentima online plaćanje, ' +
        'za vas ćemo pripremiti svu neophodnu dokumentaciju, i asistirati vam tokom procesa registracije kod banke. ' +
        'Vama samo preostaje da potpišete dokumentaciju i započnete sa korišćenjem servisa.'
    }
  ];

  isOpen: boolean[] = [];

  constructor() {
  }

  ngOnInit(): void {
    for (const faq of this.faqs) {
      this.isOpen.push(false);
    }
  }

  toggle(index: number): void {
    this.isOpen[index] = !this.isOpen[index];
  }

}
