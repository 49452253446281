export const routes: any = {
  me: {
    homepage: '',
    termsAndConditions: 'uslovi-koriscenja'
  },
  en: {
    homepage: '',
    termsAndConditions: 'terms-and-conditions'
  },
};

export enum RouteName {
  HOMEPAGE = 'homepage',
  NOT_FOUND = 'notFound',
  TERMS = 'termsAndConditions'
}

export let localeMapping: {} = {
  'sr-Latn-ME': 'me',
  'en-US': 'en',
  en: 'en',
};

export let currentLocale: string = $localize`:@@locale_code_sr_or_en|CurrentLocaleShortCode|CurrentLocaleShortCode:me`;
