import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from './layouts/not-found/not-found.component';
import {currentLocale, routes as routesTrans} from './routes';
import {seoRules} from './seo-rules';
import {PrestoLinkComponent} from './@modules/presto-link/presto-link.component';
import {PrestoLinkLayoutComponent} from './@modules/presto-link/components/presto-link-layout/presto-link-layout.component';
import {PrestoLinkTermsComponent} from './@modules/presto-link/components/presto-link-terms/presto-link-terms.component';
import {HomepageComponent} from './@modules/homepage/homepage.component';

const routes: Routes = [
  {
    path: '',
    component: HomepageComponent,
    data: {
      seoItem: seoRules.homepage[currentLocale]
    }
  },
  {
    path: 'link',
    component: PrestoLinkComponent,
    children: [
      {
        path: '',
        component: PrestoLinkLayoutComponent,
        data: {
          seoItem: seoRules.prestoLink[currentLocale]
        }
      },
      {
        path: routesTrans[currentLocale].termsAndConditions,
        component: PrestoLinkTermsComponent,
        data: {
          seoItem: seoRules.prestoLinkTerms[currentLocale]
        },
      }
    ]
  },
  {
    path: '**',
    component: NotFoundComponent,
    data: {
      seoItem: seoRules.notFound[currentLocale]
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
