import { Injectable } from '@angular/core';
import { createPopup } from '@typeform/embed';

@Injectable({
  providedIn: 'root'
})
export class TypeformService {

  id: string = 'cjkqHzpk';

  constructor() {
  }

  openTypeform(): void {
    createPopup(this.id, {size: 90}).open();
  }
}
